.backToTop {
   position: fixed;
   right: 15px;
   bottom: 15px;
   background: #ffc451;
   width: 40px;
   height: 40px;
   border-radius: 4px;
   align-items: center;
   justify-content: center;
   transition: all 0.9s;
   z-index: 996;

   &:hover {
      background: #151515;
      cursor: pointer;
   }

   svg {
      font-size: 28px;
      color: #151515;
      line-height: 0;

      &:hover {
         color: #ffc451;
      }
   }
}