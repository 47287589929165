.nordaSwiper {
  img {
    height: 500px;
  }

  @media screen and (max-width: 960px) {
    width: 100%;
  }
}

