#footer {
  background: black;
  padding: 0 0 30px 0;
  color: #fff;
  font-size: 14px;
}

#footer .footer-top {
  background: #151515;
  padding: 60px 0 30px 0;
}

#footer .footer-top .footer-info {
  margin-bottom: 35px;
}

#footer .footer-top .footer-info h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
  padding: 2px 0 2px 0;
  line-height: 1;
  font-weight: 700;
  color: #ffc451;
}

#footer .footer-top .footer-info p {
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 0;
  color: #fff;
}

#footer .footer-top .social-links {
  display: flex;
  margin-top: 10px;
}

#footer .footer-top .social-links a {
  font-size: 18px;
  color: #fff;
  padding: 5px;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

#footer .footer-top .social-links a:hover {
  background: #ffc451;
  color: #151515;
  text-decoration: none;
}

#footer .footer-top .footer-links .nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
}

#footer .footer-top .footer-links .nav a {
  color: #fff;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
}

#footer .footer-top .footer-links .nav a:hover {
  color: #ffc451;
}