.features {
  padding-top: 20px;
}

.features .icon-box {
  padding-left: 15px;

  transform: scale(0.7);

  @media screen and (max-width: 960px) {
    transform: scale(0.4);
  }
}

.features .icon-text h4 {
  font-size: 20px;
  font-weight: 700;
  margin: 5px 0 10px 60px;
}

.features .icon-box {
  font-size: 48px;
  float: left;
  color: #ffc451;
  svg {
    fill: currentColor;
  }
}
.features .image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 400px;
}
