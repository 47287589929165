header {
  position: absolute;
  z-index: 100;

  .navbar {
    width: 100%;
    position: fixed;
    transition: 0.4s;
    font-weight: 900;

    .container {
      justify-content: space-between;
    }

    .navbar-brand {
      max-height: 227px;
      transition: 0.5s;

      @media screen and (max-width: 960px) {
        max-height: 127px;
      }
    }

    svg {
      margin-right: 3px;
    }

    .navbar-toggler {
      border: 3px white solid;

      svg {
        fill: #fff3cd;
      }
    }

    a.nav-link {
      color: #F7C815;
              //rgb(249 204 78 / 75%);
      font-size:1.3rem;

      &:hover {
        color: whitesmoke;
      }
    }

      @media screen and (max-width: 960px) {
        padding: 20px 10px
      }
  }


  .navbar.scrollChange {
    background-color: #dfad16a3;
    padding: 10px;
    transition: 0.4s;
    max-height: 70px;

    .container {
      @media screen and (max-width: 960px) {
        position: relative;
        top: -25px;
      }
    }

    .navbar-brand {
      max-height: 100px;
      transition: 0.5s;
      @media screen and (max-width: 960px) {
        max-height: 100px;
        transition: 0.8s;
      }
    }

    a.nav-link {
      color: black;
      &:hover {
        color: white;
      }
    }

    a.active-scroll-spy {
      border-radius: 10px;
      box-shadow: 0 5px 0 0 #000;
      color: white;
    }

  }
}

.offcanvas {
  max-width: 60%;
}

.offcanvas-body {
  svg {
    margin-right: 6px;
  }
}